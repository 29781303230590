* {
  font-family: "Sukhumvit" !important;
}

html body {
  font-size: 16px;
  color: #080808;
  min-width: 1280px;
}

.back-icon {
  font-size: 35px;
  margin-right: 10px;
}

.back-icon:hover {
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  background-color: #e8e8e8;
}

.clickable {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

th,
td {
  text-align: initial;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
